import { Box } from "@mui/material";
import { FC } from "react";
import "./style.css";

const Skeleton: FC = () => {
    return (
        <Box className="container container-skeleton">
            <p className="title title-skeleton">
                Вся информация по автопарку в одном окне
            </p>
            <Box className="container_content">
                <Box className="container-about_left-side">
                    <img src="img/skeleton_window.svg" alt="Схематичное окно" />
                </Box>
                <Box className="container-about_right-side">
                    <p className="secondary-text">
                        Балансы ПЛАТОН <img src="img/check.svg" alt="Галочка" />
                    </p>
                    <p className="secondary-text">
                        Топливные карты{" "}
                        <img src="img/check.svg" alt="Галочка" />
                    </p>
                    <p className="secondary-text">
                        Платные дороги <img src="img/check.svg" alt="Галочка" />
                    </p>
                </Box>
            </Box>
        </Box>
    );
};

export default Skeleton;
