import {
    Box,
    Button,
    createTheme,
    SxProps,
    ThemeProvider,
} from "@mui/material";
import { Link, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import React, { FC } from "react";
import "./App.css";
import Main from "./components/main/main.component";
import TMS from "./components/TMS/TMS.component";
import FMS from "./components/FMS/FMS.component";

const Header: FC<{ scrollStyle: SxProps }> = ({ scrollStyle }) => {
    const scrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth",
        });
    };

    return (
        <Box className="header" sx={scrollStyle}>
            <img
                src="img/logo.svg"
                alt="Логотип"
                style={{ maxHeight: "50px" }}
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    minWidth: "300px",
                }}
            >
                <Link to="/" className="header_menu-links">
                    Разработка
                </Link>
                <Link to="/fms" className="header_menu-links">
                    FMS
                </Link>
                <Link to="/tms" className="header_menu-links">
                    TMS
                </Link>
            </Box>
            <Button
                variant="outlined"
                className="header_contact-button"
                onClick={scrollToBottom}
            >
                Связаться с нами
            </Button>
        </Box>
    );
};

function App() {
    const [headerStyle, setHeaderStyle] = React.useState<SxProps>({});

    const theme = createTheme({
        typography: {
            allVariants: {
                fontFamily: "Room",
            },
            button: {
                textTransform: "none",
            },
        },
    });

    React.useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 100) {
                setHeaderStyle({ boxShadow: "0 0 25px 5px #2DA2EF55" });
            } else {
                setHeaderStyle({});
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Header scrollStyle={headerStyle} />
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/tms" element={<TMS />} />
                    <Route path="/fms" element={<FMS />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default App;
