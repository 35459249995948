import "./style.css";
import { Box } from "@mui/material";
import { FC } from "react";

const TMS: FC = () => {
    return (
        <Box className="component-container">
            <Box className="container container-tms">
                <p className="title">TMS</p>
                <Box className="textbox">
                    <p>
                        <span className="glowing-text">
                            Система управления транспортными перевозками
                            (Transportation Management System, TMS)
                        </span>
                        <span>
                            {" "}
                            — логистическая платформа, использующая технологии,
                            чтобы помогать предприятиям планировать, выполнять и
                            оптимизировать физическое перемещение товаров, как
                            входящих, так и исходящих, а также обеспечивать
                            соответствие поставки требованиям и наличие нужной
                            документации.
                        </span>
                    </p>
                    <p>
                        <span>
                            {" "}
                            Система управления транспортными перевозками играет
                            центральную роль в цепях поставок, влияя на каждую
                            часть процесса: от планирования и закупок до
                            логистики и управления жизненным циклом продукта.
                            Благодаря полной прозрачности, обеспечиваемой
                            эффективной системой, планирование и выполнение
                            перевозок осуществляются более эффективно, а
                            соответственно, растет и удовлетворенность клиентов.
                        </span>
                    </p>
                </Box>
            </Box>
        </Box>
    );
};

export default TMS;
