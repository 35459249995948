import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import './style.css';

const WhyUs: FC = () => {
    return <Box className='container container-why-us'>
        <p className='title title-why-us'>Почему стоит выбрать нас?</p>
        <Grid container columnSpacing={10} rowSpacing={5}>
            <Grid item xs={12} md={6} className='container-why-us_grid-item'>
                <img src='img/leaf.svg' alt='Простота' />
                <p className='secondary-text secondary-text-why-us'>Простота</p>
                <p className='text'>Благодаря понятному интерфейсу в программах сможет разобраться кто угодно</p>
            </Grid>
            <Grid item xs={12} md={6} className='container-why-us_grid-item'>
                <img src='img/envelope.svg' alt='Поддержка' />
                <p className='secondary-text secondary-text-why-us'>Поддержка</p>
                <p className='text'>Что-то пошло не так? Напишите нам, мы с радостью вам поможем!</p>
            </Grid>
            <Grid item xs={12} md={6} className='container-why-us_grid-item'>
                <img src='img/swiss-army-knife.svg' alt='Многофункциональность' />
                <p className='secondary-text secondary-text-why-us'>Многофункциональность</p>
                <p className='text'>Обширный функционал ускоряет большинство стандартных операций и рутинных действий</p>
            </Grid>
            <Grid item xs={12} md={6} className='container-why-us_grid-item'>
                <img src='img/tie.svg' alt='Ответственность' />
                <p className='secondary-text secondary-text-why-us'>Ответственность</p>
                <p className='text'>Мы делаем продукты, за которые не стыдно, и готовы отвечать за их качество</p>
            </Grid>
        </Grid>
    </Box>
}

export default WhyUs;