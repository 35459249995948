import { Box } from "@mui/material";
import { FC } from "react";
import "./style.css";

const Example: FC = () => {
    return (
        <Box className="container container-example">
            <p className="title">Пример продукта</p>
            <img src="img/example.svg" alt="Пример продукта" />
        </Box>
    );
};

export default Example;
