import "./style.css";
import { Box } from "@mui/material";
import { FC } from "react";

const MainPage: FC = () => {
    const scrollDown = () => {
        window.scrollTo({
            top: window.innerHeight,
            behavior: "smooth",
        });
    };

    return (
        <Box
            sx={{
                maxWidth: "100vw",
                display: "flex",
                justifyContent: "center",
                background: "url(img/transition2.svg) no-repeat bottom",
            }}
        >
            <Box className="container container-main-page">
                <Box className="container-main-page_left-side">
                    <Box className="container-main-page_left-side_text">
                        <p className="main-text">
                            Современные решения для эффективного управления
                            грузоперевозками
                        </p>
                        <p className="secondary-text">
                            Мы автоматизируем логистические процессы и
                            управление автопарком
                        </p>
                    </Box>
                    <Box className="container-main-page_left-side_link">
                        <div onClick={scrollDown}>
                            <img
                                style={{ display: "inline-block" }}
                                src="img/chevron-up.svg"
                                alt="Стрелка вниз"
                            />
                            Узнать больше
                        </div>
                    </Box>
                </Box>
                <Box
                    className="container-main-page_right-side"
                    sx={{ position: "relative" }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            zIndex: "5",
                        }}
                    />
                    <video autoPlay muted loop disablePictureInPicture>
                        <source src="video/IRSA.webm" type="video/webm" />
                        <source src="video/IRSA.mp4" type="video/mp4" />
                    </video>
                </Box>
            </Box>
        </Box>
    );
};

export default MainPage;
