import "./style.css";
import { Box } from "@mui/material";
import { FC } from "react";

const About: FC = () => {
    return (
        <Box className="container container-about">
            <p className="title title-about">IRSA - вместе к успеху!</p>
            <p className="secondary-text secondary-text-about">
                Мы занимаемся разработкой уникальных инструментов, созданных
                специально для автоматизации транспортных предприятий.
            </p>
            <p className="secondary-text secondary-text-about">
                Наши продукты предоставляют обширный функционал, позволяющий
                существенно ускорять и упрощать большинство стандартных операций
                и рутинных действий.
            </p>
        </Box>
    );
};

export default About;
